<template>
    <v-app>
        <app-navigation
            v-if="!hideNav && isAuthenticated"
            class="hidden-print"
        />
        <v-main>
            <v-scroll-y-transition mode="out-in">
                <router-view />
            </v-scroll-y-transition>
        </v-main>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import AppNavigation from '@/components/AppNavigation';

export default {
    components: { AppNavigation },
    computed: {
        ...mapGetters(['isAuthenticated', 'hideNav']),
    },
};
</script>

<style scoped>
.theme--light.v-application {
    background: linear-gradient(
        0deg,
        rgba(192, 238, 174, 0.25) 0%,
        rgba(255, 255, 255, 0) 100%
    );
}

@media print {
    .theme--light .v-application {
        background: #ffffff !important;
    }
}
</style>
