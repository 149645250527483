import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/auth/LoginScreen'),
        meta: {
            authRequired: false,
            hideNav: true,
        },
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuthenticated) {
                next({
                    path: '/',
                });
            } else {
                next();
            }
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () =>
            import(
                /* webpackChunkName: "forgotPassword" */ '../views/auth/ForgotPassword.vue'
            ),
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/reset-password/:reset_key',
        name: 'ResetPassword',
        component: () =>
            import(
                /* webpackChunkName: "resetPassword" */ '../views/auth/ResetPassword.vue'
            ),
        meta: {
            authRequired: false,
            hideNav: true,
        },
    },
    {
        path: '/',
        name: 'Dashboard',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/transaction',
        name: 'Transaction',
        component: () =>
            import(
                /* webpackChunkName: "transaction" */ '../views/merchant-tools/TransactionScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "transaction" */ '../views/merchant-tools/TransactionList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/batch',
        name: 'Batch',
        component: () =>
            import(
                /* webpackChunkName: "batch" */ '../views/merchant-tools/BatchScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "batch" */ '../views/merchant-tools/BatchList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/dispute',
        name: 'Dispute',
        component: () =>
            import(
                /* webpackChunkName: "dispute" */ '../views/merchant-tools/DisputeScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "dispute" */ '../views/merchant-tools/DisputeList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/statement',
        name: 'Statement',
        component: () =>
            import(
                /* webpackChunkName: "statement" */ '../views/merchant-tools/StatementScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "statement" */ '../views/merchant-tools/StatementList'
                    ),
            },
            {
                path: 'detail/:statementId/:statementTitle',
                component: () =>
                    import(
                        /* webpackChunkName: "statement" */ '../views/merchant-tools/StatementDetail'
                    ),
                props: true,
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/ach-funding',
        name: 'AchFunding',
        component: () =>
            import(
                /* webpackChunkName: "ach-funding" */ '../views/merchant-tools/AchFundingScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "ach-funding" */ '../views/merchant-tools/AchFundingList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/virtual-terminal',
        name: 'Virtual Terminal',
        component: () =>
            import(
                /* webpackChunkName: "virtual-terminal" */ '../views/merchant-tools/VirtualTerminalScreen'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/terminals/branding',
        name: 'Branding',
        component: () =>
            import(
                /* webpackChunkName: "terminal" */ '../views/terminals/BrandingScreen'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/terminals/slideshows',
        name: 'Slideshows',
        component: () =>
            import(
                /* webpackChunkName: "slideshows" */ '../views/terminals/SlideshowScreen'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/add-slideshow',
        name: 'AddSlideshow',
        component: () =>
            import(
                /* webpackChunkName: "add-slideshow" */ '../views/terminals/AddSlideshowScreen'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/media-library',
        name: 'Media Library',
        component: () =>
            import(
                /* webpackChunkName: "media-library-screen" */ '../views/terminals/MediaLibraryScreen'
            ),
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/survey-results',
        name: 'Survey Results',
        component: () =>
            import(
                /* webpackChunkName: "survey-results" */ '../views/merchant-tools/SurveyResultsScreen'
            ),
        children: [
            {
                path: 'list',
                component: () =>
                    import(
                        /* webpackChunkName: "survey-results" */ '../views/merchant-tools/SurveyResultsList'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
    {
        path: '/terms-conditions',
        name: 'Terms and Conditions',
        component: () =>
            import(
                /* webpackChunkName: "terms-conditions" */ '../views/merchant-tools/TermsAndConditionsScreen'
            ),
        children: [
            {
                path: 'manage',
                component: () =>
                    import(
                        /* webpackChunkName: "terms-conditions" */ '../views/merchant-tools/TermsAndConditionsManage'
                    ),
            },
        ],
        meta: {
            authRequired: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    store.commit(
        'setHideNav',
        to.matched.some((record) => record.meta.hideNav),
    );

    // Applies to any route with authRequired defined as true
    // TODO: Change to require auth by default, unless set otherwise
    if (to.matched.some((record) => record.meta.authRequired)) {
        if (!store.getters.isAuthenticated) {
            next({
                path: '/login',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
