export default {
    setIsAuthenticated(state, payload) {
        state.isAuthenticated = payload;
    },
    setHideNav(state, payload) {
        state.hideNav = payload;
    },
    setCurrentLocationId(state, payload) {
        state.currentLocationId = payload;
    },
    setCurrentLocationName(state, payload) {
        state.currentLocationName = payload;
    },
    setAuthenticatedLocations(state, payload) {
        state.authenticatedLocations = payload;
    },
};
