export default {
    getInternalBaseUrl: () => {
        let full = window.location.host;
        let parts = full.split('.');
        let subdomain =
            process.env.NODE_ENV === 'development' ? 'beta' : parts[0];

        return 'https://' + subdomain + '.corestore.shop';
    },
    getInternalControllerPrefix: () => {
        return '/index.php/';
    },
    getInternalApiPrefix: () => {
        // Change this if we set up multiple APIs
        return process.env.NODE_ENV === 'development' ? '/api/v1/' : '/api/v1/';
    },
    getInternalLoginController: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalControllerPrefix +
            'login'
        );
    },
    getInternalHomeController: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalControllerPrefix +
            'home'
        );
    },
    getInternalEmployeePreferencesController: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalControllerPrefix +
            'employee_preferences'
        );
    },
    getInternalLocationsController: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalControllerPrefix +
            'locations'
        );
    },
    getInternalCoreClearController: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalControllerPrefix +
            'coreclear'
        );
    },
    getInternalMxController: (state, getters) => {
        return (
            getters.getInternalBaseUrl +
            getters.getInternalControllerPrefix +
            'mx'
        );
    },
    getAppColors: (state) => {
        return state.colors;
    },
    isAuthenticated: (state) => {
        return state.isAuthenticated;
    },
    hideNav: (state) => {
        return state.hideNav;
    },
    getCurrentLocationId: (state) => {
        return state.currentLocationId;
    },
    getCurrentLocationName: (state) => {
        return state.currentLocationName;
    },
    getAuthenticatedLocations: (state) => {
        return state.authenticatedLocations;
    },
    getQuickDateRangeChoices: (state) => {
        return state.quickDateRangeChoices;
    },
    getBlockchypDateRangeChoices: (state) => {
        return state.quickDateRangeChoices.filter((dateRangeChoice) => {
            return dateRangeChoice.useForBlockchyp === true;
        });
    },
};
