import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/css/style.css';

import Vue from 'vue';
import VueCardFormat from 'vue-credit-card-validation';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueCardFormat);

let app;

const initialize = () => {
    if (!app) {
        app = new Vue({
            vuetify,
            router,
            store,
            render: (h) => h(App),
        }).$mount('#app');
    }
};

store.dispatch('checkLoggedIn').then(() => {
    initialize();
});
