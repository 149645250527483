<template>
    <div>
        <v-navigation-drawer v-model="drawer" width="250" bottom app>
            <v-list>
                <v-list-item-group
                    v-model="itemGroup"
                    active-class="success--text"
                >
                    <template v-for="(item, index) in items">
                        <template>
                            <template v-if="item.subItems.length > 0">
                                <v-list-group
                                    :key="index"
                                    :prepend-icon="item.icon"
                                    :group="item.route"
                                    no-action
                                >
                                    <template v-slot:activator>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </template>
                                    <v-list-item
                                        v-for="(
                                            subItem, subIndex
                                        ) in item.subItems"
                                        :key="subIndex"
                                        :to="'/' + subItem.route"
                                    >
                                        <v-list-item-title>
                                            {{ subItem.title }}
                                        </v-list-item-title>
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ subItem.icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list-group>
                            </template>
                            <template v-else>
                                <v-list-item
                                    :key="index"
                                    :to="'/' + item.route"
                                    active-class="success--text"
                                >
                                    <v-list-item-action>
                                        <v-icon>
                                            {{ item.icon }}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.title }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </template>
                    </template>
                </v-list-item-group>
            </v-list>
            <template v-slot:prepend>
                <div class="pa-2 black">
                    <v-img
                        lazy-src="@/assets/logo.png"
                        max-height="150"
                        max-width="250"
                        src="@/assets/logo.png"
                    />
                </div>
            </template>
            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block outlined color="error" @click="logout">
                        <v-icon left>logout</v-icon>
                        Logout
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar app :color="getAppColors.primary" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
            <v-spacer />
            <v-btn
                color="white"
                class="mx-4"
                href="https://coreclear.com/device-8500"
                target="_blank"
                outlined
                dark
            >
                <v-icon left>mdi-point-of-sale</v-icon>
                Order Terminals
            </v-btn>
            <v-divider vertical inset />
            <v-toolbar-items>
                <v-menu
                    offset-y
                    :close-on-content-click="false"
                    v-if="allAuthenticatedLocations.length > 1"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn text v-on="on">
                            {{ getCurrentLocationName }}
                            <v-icon right>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-text-field
                            :color="getAppColors.primary"
                            class="pa-2"
                            outlined
                            hide-details
                            dense
                            autofocus
                            append-icon="mdi-magnify"
                            @input="locationFilter($event)"
                        ></v-text-field>
                        <v-divider></v-divider>
                        <v-list-item
                            link
                            v-for="location in locations"
                            :key="location.location_id"
                            @click="changeCurrentLocation(location.location_id)"
                        >
                            <v-icon left>mdi-map-marker</v-icon>
                            <v-list-item-title>{{
                                location.name
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar-items>
        </v-app-bar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppNavigation',
    data() {
        return {
            appTitle: 'coreCLEAR',
            drawer: null,
            itemGroup: null,
            activeMenu: null,
            activeSubmenu: null,
            items: [
                {
                    title: 'Dashboard',
                    icon: 'dashboard',
                    route: '',
                    subItems: [],
                },
                {
                    title: 'Transactions',
                    icon: 'list_alt',
                    route: 'transaction/list',
                    subItems: [],
                },
                {
                    title: 'Batches',
                    icon: 'list_alt',
                    route: 'batch/list',
                    subItems: [],
                },
                {
                    title: 'Disputes',
                    icon: 'list_alt',
                    route: 'dispute/list',
                    subItems: [],
                },
                {
                    title: 'Statements',
                    icon: 'list_alt',
                    route: 'statement/list',
                    subItems: [],
                },
                {
                    title: 'ACH Funding',
                    icon: 'list_alt',
                    route: 'ach-funding/list',
                    subItems: [],
                },
                {
                    title: 'Terminals',
                    icon: 'mdi-point-of-sale',
                    subItems: [
                        {
                            title: 'Branding',
                            icon: 'mdi-watermark',
                            route: 'terminals/branding',
                        },
                        {
                            title: 'Slideshows',
                            icon: 'mdi-play-box-outline',
                            route: 'terminals/slideshows',
                        },
                        {
                            title: 'Media Library',
                            icon: 'mdi-folder-multiple-image',
                            route: 'media-library',
                        },
                        {
                            title: 'Surveys',
                            icon: 'list_alt',
                            route: 'survey-results/list',
                        },
                        // {
                        //     title: 'Terms and Conditions',
                        //     icon: 'mdi-file-document-edit',
                        //     route: 'terms-conditions/manage',
                        // },
                    ],
                },
                {
                    title: 'Virtual Terminal',
                    icon: 'mdi-point-of-sale',
                    route: 'virtual-terminal',
                    subItems: [],
                },
            ],
            locations: [],
            allAuthenticatedLocations: [],
        };
    },
    computed: {
        ...mapGetters([
            'getAppColors',
            'getCurrentLocationId',
            'getCurrentLocationName',
            'getAuthenticatedLocations',
        ]),
    },
    mounted() {
        this.locations = this.getAuthenticatedLocations;
        this.allAuthenticatedLocations = this.getAuthenticatedLocations;
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
        },

        locationFilter(valueToFilter) {
            this.locations = this.allAuthenticatedLocations.filter(
                (location) =>
                    location.name
                        .toLowerCase()
                        .indexOf(valueToFilter.toLowerCase()) !== -1,
            );
        },
        changeCurrentLocation(locationId) {
            this.$store.dispatch('changeCurrentLocation', locationId);
        },
    },
};
</script>
